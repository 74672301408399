<div class="best-offers general-padding">
  <div class="font__nunino--extra-bold font__size--20 font__line-height--20 font__letter-spacing--83-n">{{ title }}</div>
  <div class="best-offers__overflow" [ngClass]="setWidthProductsStyles(0, true)">
    <div #offerContainer class="best-offers__container flex flex__row flex-nowrap flex-j-flex-start">
      <ng-container *ngFor="let promotion of promotions; let i=index">
        <div class="best-offers__card flex flex__column" (click)="onSelect(promotion)" [ngClass]="setWidthProductsStyles(i)">
          <div class="box__offer">
            <div class="box__offer--image">
              <img [src]="promotion?.image" [alt]="promotion?.product_name">
            </div>
            <div class="best-offers__card--percentage box box__percentage flex flex__nowrap flex__row flex__align-center flex__j-center">
              <div class="mr__2">-{{ promotion?.discount }}%</div>
            </div>
            <div class="box__owner">
              <img [src]="promotion?.store_image" [alt]="promotion?.product_name">
            </div>
          </div>
          <div class="ellipsis font__nunino--regular font__size--12 font__line-height--14 font__letter-spacing--0 txt-gray-soft-v">{{ promotion?.product_name }}</div>
          <div class="flex flex__nowrap flex__row flex__j-flex-start">
            <div class="mr__2 font__nunino--extra-bold font__size--14 font__line-height--18 font__letter-spacing--0 txt-black-a">{{ promotion?.currency }}{{ promotion?.price_with_discount }}</div>
            <div class="box__prime flex flex__row flex-nowrap flex-j-center flex__align-center">
              <div class="box__prime--crow"></div>
            </div>
          </div>
          <div class="txt__decoration-line-through font__nunino--regular font__size--14 font__line-height--18 font__letter-spacing--0 txt-gray-soft">{{ promotion?.currency }}{{ promotion?.price }}</div>
        </div>
      </ng-container>
    </div>
</div>