import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnInit, ViewChild } from '@angular/core';

import { TreatmentValuesService } from 'src/app/services/treatment-values.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-subscribe-prime',
  templateUrl: './subscribe-prime.component.html',
  styleUrls: ['./subscribe-prime.component.sass'],
})
export class SubscribePrimeComponent implements OnInit, AfterViewChecked {
  @ViewChild('btnSubscribe', { static: false }) btnSubscribe: ElementRef;
  @ViewChild('subscribePrime', { static: true }) subscribePrime: ElementRef;
  @ViewChild('moreBenefist', { static: true }) moreBenefist: ElementRef;
  @ViewChild('arrow', { static: true }) arrow: ElementRef;
  @ViewChild('description', { static: true }) description: ElementRef;
  @ViewChild('subscribeContainer', { static: false }) subscribeContainer: ElementRef;
  @Input() isPromotion: false;

  private hasDescription = false;

  public showIconPercentage = false;
  public notPrimeValues: any;
  public primeBtn: any;
  public profit: any;

  constructor(
    private treatmentValueService: TreatmentValuesService,
    private userService: UserService,
    private cdref: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.showIconPercentage = window.innerWidth >= 504;
  }

  ngAfterViewChecked(): void {
    if (this.description && this.btnSubscribe && !this.hasDescription) {
      this.treatmentValueService.getTreatmentValues().subscribe((res) => {
        if (res && res.sections) {
          this.notPrimeValues = res.sections.filter(x => x.type === 'prime_section')[0];
          this.profit = this.notPrimeValues.data;
          this.primeBtn = this.notPrimeValues.button;
          const descriptionElement = this.description.nativeElement;
          descriptionElement.style.paddingTop = window.innerWidth < 504 ? '16px' : '0';
          this.btnSubscribe.nativeElement.style.backgroundColor = `${this.primeBtn.background_color}`;

          if (this.isPromotion) {
            this.subscribeContainer.nativeElement.style.paddingLeft = '0';
            this.subscribeContainer.nativeElement.style.paddingRight = '0';
          }

          this.cdref.detectChanges();
        }
      }, error => {
        console.log(error);
      });

      this.hasDescription = true;
    }
  }

  public setFontSize(): string {
    if (window.innerWidth >= 504) {
      return 'font__size--16';
    } else if (window.innerWidth >= 375) {
      return 'font__size--14';
    } else {
      return 'font__size--12';
    }
  }

  public onArrowEvent(): void {
    const primeElement = this.subscribePrime.nativeElement;
    const benefistElement = this.moreBenefist.nativeElement;
    const arrowElement = this.arrow.nativeElement;
    const isOpen = primeElement.classList.contains('box-open');

    primeElement.classList.replace(isOpen ? 'box-open' : 'box-close', isOpen ? 'box-close' : 'box-open');
    benefistElement.classList.replace(isOpen ? 'opacity-1' : 'opacity-0', isOpen ? 'opacity-0' : 'opacity-1');
    arrowElement.classList.replace(isOpen ? 'arrow__up' : 'arrow__down', isOpen ? 'arrow__down' : 'arrow__up');
  }

  public onSubscribePrime(): void {
    window.location.href = this.primeBtn.deeplink;
  }
}
