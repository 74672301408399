import { Component, Input, OnInit } from '@angular/core';

import { SplitConfigurationService } from 'src/app/services/split-configuration.service';
import { PrimeService } from 'src/app/services/prime.service';
import { TreatmentValuesService } from 'src/app/services/treatment-values.service';
import { AppWindow } from './components/header/header.component';
declare const window: AppWindow;
@Component({
  selector: 'app-webview',
  templateUrl: './webview.component.html',
  styleUrls: ['./webview.component.sass']
})
export class WebviewComponent implements OnInit {
  private userID: string;

  public requestError = false;
  public notPrimeModal;
  public splitReady = false;
  public addresses: any;
  public treatmentValue: any;
  public isPrime: boolean;
  public loader = true;
  public bestOffers = ['initial'];
  public subscribeModalUrl = '';
  public title = 'webview-prime-week';

  constructor(
    private treatmentValueService: TreatmentValuesService,
    private splitService: SplitConfigurationService,
    private primeService: PrimeService,
  ) {
    this.getUserAddresses();
  }

  ngOnInit(): void {
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
    this.getTreatmentValues();
  }

  private getSplitValues(country = 'CO'): void {
    this.splitService.getSplitClient(this.userID).subscribe(({ client, client_on }) => {
      this.splitService.getTreatments(country.toUpperCase());
      this.splitReady = client_on;
    }, error => {
      this.splitReady = false;
      this.requestError = true;
    });
  }

  private getTreatmentValues(): void {
    this.treatmentValueService.getTreatmentValues().subscribe(res => {
      if (res && res.sections) {
        this.treatmentValue = res;
        const primeSection = res.sections.filter(x => x.type === 'prime_section')[0];
        this.subscribeModalUrl = primeSection.button.deeplink;
      }
    }, error => {
      this.loader = false;
      this.requestError = true;
    });
  }

  private getUserAddresses(): void {
    this.primeService.getUserAddresses().subscribe(res => {
      const [ userAdrress ] = res.addresses.filter(item => item.active);

      if (userAdrress) {
        this.addresses = {
          lng: userAdrress.lng,
          lat: userAdrress.lat,
        };
        const country = localStorage.getItem('country');
        this.userID = `${country.toUpperCase()}_${userAdrress.application_user_id}`;
        this.getSplitValues(country === 'dev' ? 'co' : country);
      }
    }, error => {
      this.loader = false;
      this.requestError = true;
    });
  }

  public onModalCategory(e): void {
    this.notPrimeModal = e;
  }

  public onGetBestOffers(e): void {
    this.loader = false;
    this.bestOffers = e;
  }

  public onGetPrime(e): void {
    this.loader = false;
    this.isPrime = e;
  }

  public onRequestError(e): void {
    this.loader = false;
    this.requestError = e;
  }

  public onClose(): void {
    if (window.app !== undefined) {
      window.app.endCall();
    }
    if (window.appInterface !== undefined) {
      window.appInterface.endCall();
    }
    if (window.webkit !== undefined) {
      window.webkit.messageHandlers.endCall.postMessage('');
    }
  }
}
