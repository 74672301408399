import { AfterViewChecked, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';

@Component({
  selector: 'app-not-prime',
  templateUrl: './not-prime.component.html',
  styleUrls: ['./not-prime.component.sass']
})
export class NotPrimeComponent implements OnInit, OnChanges, AfterViewChecked {
  @ViewChild('headerImage', { static: false }) headerImage: ElementRef;
  @Input() primeModal: any;
  @Input() subscribeModalUrl: any;
  @Input() isPrime: boolean;

  public showModal = false;
  public tooltip = {image: '', title: '', subtitle: '', background_color: 'rgb(46, 44, 54)'};
  public sponsoredProduct = {image: '', title: '', subtitle: '', discount: '', price: ' ****', deeplink: ''};
  public price: any;
  public discount: any;
  private hasImageElement = false;
  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewChecked(): void {
    if (this.headerImage && !this.hasImageElement) {
      this.headerImage.nativeElement.style.backgroundColor = `${this.tooltip.background_color}`;
      this.hasImageElement = true;
    }
  }

  // tslint:disable-next-line:typedef
  ngOnChanges(changes: SimpleChanges) {
    for (const propertyName in changes) {
      if (changes[propertyName]) {
        const change: any = changes[propertyName].currentValue;

        switch (propertyName) {
          default:
            if (change){
              const { action, category } = change;
              this.showModal = action;
              this.tooltip = category || this.tooltip;
              this.sponsoredProduct = category && category.sponsored_product || this.sponsoredProduct;
              this.tooltip.subtitle = this.setNameCategory(this.tooltip.subtitle);
              this.setPriceValues();
            }

            break;
        }
      }
    }
  }

  private setNameCategory(name): string {
    if (name.length > 36) {
      return `${name.substring(0, 33)}...`;
    }

    return name;
  }

  private setPriceValues(): void {
    const [ price, discount ] = this.sponsoredProduct.price.split(' **');

    this.price = price;
    this.discount = discount.split('**')[0];
  }

  public onCloseModal(): void {
    this.showModal = false;
    this.tooltip = {image: '', title: '', subtitle: '', background_color: 'rgb(46, 44, 54)'};
    this.sponsoredProduct = {image: '', title: '', subtitle: '', discount: '', price: ' ****', deeplink: ''};
  }

  public onProduct(e): void {
    window.location.href = this.isPrime ? this.sponsoredProduct.deeplink : this.subscribeModalUrl;
  }
}
