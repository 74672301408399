import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';

import { PrimeService } from 'src/app/services/prime.service';
import { TreatmentValuesService } from 'src/app/services/treatment-values.service';
import { UserService } from 'src/app/services/user.service';
@Component({
  selector: 'app-best-offers',
  templateUrl: './best-offers.component.html',
  styleUrls: ['./best-offers.component.sass']
})
export class BestOffersComponent implements OnInit, OnChanges {
  @ViewChild('offerContainer', { read: ElementRef, static: true }) offerContainer: ElementRef;
  @Input() isPrime: any;
  @Input() treatmentValue: any;
  @Input() bestOffers: any;

  private subscribeModalUrl = '';
  public promotions = [];
  public title = '';

  constructor() { }

  ngOnInit(): void {
  }

  // tslint:disable-next-line:typedef
  ngOnChanges(changes: SimpleChanges) {
    for (const propertyName in changes) {
      if (changes[propertyName]) {
        const change: any = changes[propertyName].currentValue;

        switch (propertyName) {
          case 'treatmentValue':
            if (change && change.sections) {
              const primeSection = change.sections.filter(x => x.type === 'prime_section')[0];

              this.subscribeModalUrl = primeSection.button.deeplink;
              this.title = change.sections.filter(x => x.type === 'featured_offers')[0].title;
            }
            break;
          case 'bestOffers':
            if (change && change.length) {
              this.promotions = change;
            }
            break;
        }
      }
    }
  }

  public onSelect(promotion): void {
    window.location.href = this.isPrime ? promotion.link.sref : this.subscribeModalUrl;
  }

  public setWidthProductsStyles(i, isContainer = false): string {
    let divStyle = '';
    let divWidth = 0;

    if (window.innerWidth >= 411) {
      divStyle = isContainer ? 'box-container-xl' : 'box-width-xl';
      divWidth = 152;
    } else if (window.innerWidth >= 375){
      divStyle = isContainer ? 'box-container-md' : 'box-width-md';
      divWidth = 120;
    } else {
      divStyle = isContainer ? 'box-container-xs' : 'box-width-xs';
      divWidth = 104;
    }

    if (i === (this.promotions.length - 1)) {
      this.offerContainer.nativeElement.style.width = `${this.promotions.length * divWidth}pt`;
    }

    return divStyle;
  }
}
