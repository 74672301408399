import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Components
import { HeaderComponent } from './components/header/header.component';
import { CategoriesComponent } from './components/categories/categories.component';
import { BestOffersComponent } from './components/best-offers/best-offers.component';
import { NotPrimeComponent } from './components/not-prime/not-prime.component';
import { FeatureProductComponent } from './components/feature-product/feature-product.component';
import { PromotionsDayComponent } from './components/promotions-day/promotions-day.component';
import { WebviewComponent } from '../webview/webview.component';

// Modules
import { SwiperModule } from 'swiper/angular';
import { SharedModule } from '../shared/shared.module';

// Routing
import { WebviewRoutingModule } from './webview.routing';
import { AuthGuard } from 'src/app/services/auth-guard.service';

@NgModule({
  declarations: [
    HeaderComponent,
    CategoriesComponent,
    BestOffersComponent,
    NotPrimeComponent,
    FeatureProductComponent,
    PromotionsDayComponent,
    WebviewComponent,
  ],
  imports: [
    CommonModule,
    SwiperModule,
    WebviewRoutingModule,
    SharedModule,
  ],
  providers: [AuthGuard],
  exports: [
    HeaderComponent,
    CategoriesComponent,
    BestOffersComponent,
    NotPrimeComponent,
    FeatureProductComponent,
    PromotionsDayComponent,
  ]
})
export class WebviewModule { }
