<div #header class="header">
  <div class="header__img">
    <img [src]="headerData?.bg_image" alt="header image">
  </div>

  <div class="header__container">
    <div class="flex flex__nowrap flex__j-flex-start">
      <div class="header__btn-row" (click)="onClose()"></div>
      <div class="header__img-prime">
        <img [src]="headerData?.image" alt="header image">
      </div>
    </div>
    <div class="header__title" [ngClass]="setFontSize()">
      <span class="txt-gray-soft">{{ headerData?.split_title.left }}</span>
      {{ headerData?.split_title.center }}
      <span class="txt-gray-soft">{{ headerData?.split_title.right }}</span>
    </div>
    <div class="header__lines-separator txt-gray-soft-v">
      ---
    </div>
    <div class="header__subtitle txt-gray-soft" [ngClass]="setFontSize(true)">
      {{ headerData?.subtitle }}
    </div>
  </div>
</div>
