import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/services/auth-guard.service';
import { WebviewComponent } from './webview.component';


const routes: Routes = [
  { path: '', component:  WebviewComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
})
export class WebviewRoutingModule { }
