export const environment = {
  production: false,
  gateway: {
    baseUri: 'http://microservices.dev.rappi.com',
    internalBaseUri: 'http://internal-microservices.dev.rappi.com',
  },
  mx: {
    api: 'https://microservices.dev.rappi.com',
  },
  br: {
    api: 'https://microservices.dev.rappi.com',
  },
  co: {
    api: 'https://microservices.dev.rappi.com',
  },
  ar: {
    api: 'https://microservices.dev.rappi.com',
  },
  pe: {
    api: 'https://microservices.dev.rappi.com',
  },
  cl: {
    api: 'https://microservices.dev.rappi.com',
  },
  uy: {
    api: 'https://microservices.dev.rappi.com',
  },
  ec: {
    api: 'https://microservices.dev.rappi.com',
  },
  cr: {
    api: 'https://microservices.dev.rappi.com',
  },
  bo: {
    api: 'https://microservices.dev.rappi.com',
  },
  default_country: 'co',
  split: {
    name: 'prime_week_config',
    js_authorizationKey: 'i6s7nqlqn2qtucvs2ibscnhhefb49c8r7hs'
  },
  baseStaticsUri: 'https://images.rappi.com',
};
