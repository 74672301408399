import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-prime-error',
  templateUrl: './prime-error.component.html',
  styleUrls: ['./prime-error.component.sass']
})
export class PrimeErrorComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) {
    localStorage.removeItem('country');
    localStorage.removeItem('userToken');
  }

  ngOnInit(): void {
    if (!localStorage.getItem('userToken')) {
      this.route.queryParams.subscribe(({country, userToken}) => {
        if (country && userToken) {
          localStorage.setItem('country', `${country === 'dev' ? 'co' : country}`);
          localStorage.setItem('userToken', userToken);

          this.router.navigateByUrl('/prime-week').then();
        }
      });
    } else {
      this.router.navigateByUrl('/prime-week').then();
    }
  }

}
