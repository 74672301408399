<div class="feature-product general-padding" *ngIf="slideShowItems.length">
  <div class="font__nunino--extra-bold font__size--20 font__line-height--20 font__letter-spacing--83-n">
    {{ sectionTitle }}
  </div>
  <div class="feature-product__container" *ngIf="swiperConfig">
    <swiper [config]="swiperConfig">
      <ng-template swiperSlide *ngFor="let item of slideShowItems">
        <div class="feature-product__container--image" (click)="onProduct(item)">
          <img [src]="item?.discount_image" [alt]="item?.title">
          <div class="feature-product__description">
            <div #featureProductDescription class="feature-product__description--box flex flex__row flex__nowrap flex__align-center">
              <div class="feature-product__box feature-product__box--img" *ngIf="showSmallImage">
                <img [src]="item?.logo" [alt]="item?.subtitle">
              </div>
              <div class="feature-product__box--description" [ngClass]="{'feature-product__box': showOwnerTitle}">
                <div class="ellipsis title font__nunino--extra-bold font__size--14 font__line-height--18 font__letter-spacing--0 txt-black-a">
                  {{ item?.title }}
                </div>
                <div class="flex flex__row flex__nowrap flex__j-flex-start flex__algin-flex-end">
                  <div class="mr__2 font__nunino--extra-bold font__size--12 font__line-height--14 font__letter-spacing--0 txt-black-a">
                    {{ item?.new_discount }}
                  </div>
                  <div class="mr__2 font__nunino--regular font__size--12 font__line-height--14 font__letter-spacing--0 txt-gray-soft txt__decoration-line-through">
                    {{ item?.new_price }}
                  </div>
                  <div class="box__prime flex flex__row flex-nowrap flex-j-center flex__align-center">
                    <div class="box__prime--crow"></div>
                  </div>
                </div>
              </div>
              <div class="feature-product__box--owner" *ngIf="showOwnerTitle">
                <div class="ellipsis font__nunino--regular font__size--12 font__line-height--14 font__letter-spacing--0 txt-gray-soft-v">
                  {{ item?.subtitle }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </swiper>
  </div>
</div>