<div class="loader" *ngIf="loader">
  <div class="btn-row" (click)="onClose()"></div>
  <div class="prime bg-black-a flex flex__row flex__nowrap flex__j-center flex__align-center">
    <div class="prime__bg-img">
      <img src="/assets/img/loader/prime_week_bg.png" alt="bg image prime week">
    </div>
    <div class="prime__logo-img flex flex__row flex__nowrap flex__j-center flex__align-center">
      <img src="/assets/img/loader/prime_week.png" alt="Prime week logo">
    </div>
  </div>
</div>
<div>
  <app-exception-screens *ngIf="requestError || !bestOffers.length" [bestOffers]="bestOffers" [requestError]="requestError"></app-exception-screens>
</div>
<header>
  <app-header [splitReady]="splitReady"></app-header>
</header>
<nav>
  <app-categories (notPrimeModal)="onModalCategory($event)" (requestError)="onRequestError($event)"></app-categories>
</nav>
<section>
  <app-best-offers [isPrime]="isPrime" [treatmentValue]="treatmentValue" [bestOffers]="bestOffers"></app-best-offers>
</section>
<section *ngIf="!isPrime">
  <app-subscribe-prime></app-subscribe-prime>
</section>
<section>
  <app-feature-product [treatmentValue]="treatmentValue" [isPrime]="isPrime"></app-feature-product>
</section>
<section>
  <app-promotions-day [addresses]="addresses" [treatmentValue]="treatmentValue" (getPrime)="onGetPrime($event)"
    (getBestOffers)="onGetBestOffers($event)" (requestError)="onRequestError($event)"></app-promotions-day>
</section>
<div>
  <app-not-prime [primeModal]="notPrimeModal" [subscribeModalUrl]="subscribeModalUrl" [isPrime]="isPrime"></app-not-prime>
</div>