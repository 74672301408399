import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';

import { TreatmentValuesService } from 'src/app/services/treatment-values.service';
declare const window: AppWindow;

export interface AppWindow {
  appInterface: any;
  webkit: any;
  app: any;
  open: any;
  innerWidth: any;
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass']
})
export class HeaderComponent implements OnInit, OnChanges {
  @ViewChild('header', {static: true}) headerElement: ElementRef;
  @Input() splitReady = false;

  public headerData;

  constructor(
    private treatmentService: TreatmentValuesService,
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const propertyName in changes) {
      if (changes[propertyName]) {
        const change: any = changes[propertyName].currentValue;

        switch (propertyName) {
          default:
            if (change){
              this.treatmentService.getTreatmentValues().subscribe(({ header }) => {
                this.headerData = header;

                if (header && header.title) {
                  const [leftTitle, tempRighTitle] = header.title.split('[[');
                  const [centerTitle, rightTitle] = tempRighTitle.split(']]');

                  this.headerData.split_title = { left: leftTitle, center: centerTitle, right: rightTitle };
                }
              });
            }

            break;
        }
      }
    }
  }

  public setFontSize(isSubtitle = false): string {
    if (window.innerWidth >= 411) {
      return isSubtitle ? 'font__size--16' : 'font__size--20';
    } else if (window.innerWidth >= 375){
      return isSubtitle ? 'font__size--14' : 'font__size--19';
    } else {
      return isSubtitle ? 'font__size--12' : 'font__size--15';
    }
  }

  public onClose(): void {
    if (window.app !== undefined) {
      window.app.endCall();
    }
    if (window.appInterface !== undefined) {
      window.appInterface.endCall();
    }
    if (window.webkit !== undefined) {
      window.webkit.messageHandlers.endCall.postMessage('');
    }
  }
}
