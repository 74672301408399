import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate {
  public token = '';

  constructor(
    private router: Router
  ) {
    this.token = localStorage.getItem('userToken');
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.token) {
      return true;
    } else {
      this.router.navigate(['/']).then();
      return false;
    }
  }
}
