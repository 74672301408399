import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';

import { TreatmentValuesService } from 'src/app/services/treatment-values.service';
import { PrimeService } from 'src/app/services/prime.service';
import * as globals from 'src/app/utils/globals';
import { DateTime, Interval } from 'luxon';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.sass']
})
export class CategoriesComponent implements OnInit {
  @ViewChild('categoriesOverflow', { static: true }) categoriesOverflow: ElementRef;
  @ViewChild('categoriesContainer', { static: true }) categoriesContainer: ElementRef;
  @Output() notPrimeModal: EventEmitter<any> = new EventEmitter<any>();
  @Output() requestError: EventEmitter<any> = new EventEmitter<any>();

  private baseScrollLeft = 140;
  private zone: Date;

  public categories = [];
  public splitCategories = [];
  public selectecNoPrimeDay = [];
  public currentDay: DateTime;

  constructor(
    private treatmentValueService: TreatmentValuesService,
    private primeService: PrimeService
  ) { }

  ngOnInit(): void {
    this.treatmentValueService.getTreatmentValues().subscribe((res) => {
      if (res && res.sections) {
        this.splitCategories = res.sections.filter(x => x.type === 'carousel_days_offers')[0].data;
      }

      this.getCategoriesCurrentWeek();
    });
  }

  private getCategoriesCurrentWeek(): void {
    this.primeService.getCategoriesCurrentWeek().subscribe(res => {
      this.categories = res.map(day => {
        day.date = new Date(day.available_date);

        return day;
      });

      this.categories = this.categories.sort((a, b) => a.date - b.date);
      this.categories.forEach((category, i) => {
        category.icon = this.splitCategories[i] && this.splitCategories[i].icon;
        category.name = this.splitCategories[i] && this.splitCategories[i].title || category.name;
        category.tooltip = this.splitCategories[i] && this.splitCategories[i].tooltip;
        category.date = this.splitCategories[i] && this.splitCategories[i].day_of_week;

        if (category.is_active && i > 1) {
          this.categoriesOverflow.nativeElement.scrollLeft = this.baseScrollLeft * (i - 1);
        }
      });
      this.categoriesContainer.nativeElement.style.width = `${this.categories.length * 106}pt`;
    }, error => {
      this.requestError.emit(true);
    });
  }

  public onSelect(isActive: boolean, category: any): void {
    this.zone = globals.COUNTRIES[localStorage.getItem('country')];
    this.currentDay = DateTime.now().setZone(this.zone);
    const formatDate = DateTime.fromISO(`${category.available_date}`);

    if (!isActive && formatDate > this.currentDay && category.tooltip) {
      this.notPrimeModal.emit({ action: true, category: category.tooltip });
    }
  }
}
