import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TreatmentValuesService {

  private routerInfo: BehaviorSubject<any>;

  constructor() {
    this.routerInfo = new BehaviorSubject<any>(null);
  }

  getTreatmentValues(): Observable<any> {
    return this.routerInfo.asObservable();
  }

  setTreatmentValues(newValue): void {
    this.routerInfo.next(newValue);
  }
}
