<div #subscribeContainer class="subscribe general-padding">
  <div #subscribePrime id="subscribe-prime" class="subscribe__box box-close">
    <div class="subscribe__content">
      <div class="mb__24 flex flex__row flex__nowrap flex__j-flex-start flex__align-center">
        <div class="subscribe__content--icon" *ngIf="showIconPercentage"></div>
        <div #description id="description" class="subscribe__content--description">
          <div class="font__nunino--extra-bold font__line-height--20 font__letter-spacing--0 txt-black-a"
            [ngClass]="setFontSize()">
            {{ notPrimeValues?.title }}
          </div>
          <div class="flex flex__row flex__nowrap flex__j-flex-start flex__align-center">
            <div class="mr__6 font__nunino--regular font__line-height--20 font__letter-spacing--0 txt-black-a"
            [ngClass]="setFontSize()">
              {{ notPrimeValues?.subtitle }}
            </div>
            <div class="box__prime flex flex__row flex-nowrap flex-j-center flex__align-center">
              <div class="box__prime--crow"></div>
            </div>
          </div>
        </div>
      </div>
      <div #moreBenefist id="more-benefist" class="mb__24 font__nunino--regular font__line-height--20 font__letter-spacing--0 txt-black-a opacity-0"
        [ngClass]="setFontSize()">
        {{ profit?.title }}
      </div>
      <div class=" mb__24 flex flex__row flex__nowrap flex__j-flex-start flex__align-center" *ngFor="let benefit of profit?.benefits">
        <div class="mr__6 red-check"></div>
        <div class="font__nunino--extra-bold font__line-height--20 font__letter-spacing--0 txt-black-a" [ngClass]="setFontSize()">
          {{ benefit?.title }}
        </div>
      </div>
      <button #btnSubscribe class="btn-subscribe" (click)="onSubscribePrime()">
        <span class="font__nunino--regular font__line-height--20 font__letter-spacing--0">{{ primeBtn?.title }}</span>
      </button>
    </div>
    <div #arrow id="arrow" class="subscribe__arrow arrow arrow__down" (click)="onArrowEvent()"></div>
  </div>
</div>
