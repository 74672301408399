import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { HttpRequest, HttpHandler } from '@angular/common/http';
import { Observable, from } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class InterceptorService implements HttpInterceptor {
  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${localStorage.getItem('userToken')}`,
      AUTH_USER: '1'
    });

    const reqClone = request.clone({
      headers
    });

    return next.handle( reqClone );
  }
}

