<div class="categories general-padding">
  <div #categoriesOverflow class="categories__overflow">
    <div #categoriesContainer class="categories__container flex flex__row flex-nowrap flex-j-flex-start">
      <ng-container *ngFor="let category of categories; let i=index">
        <div class="categories__card flex flex__column" (click)="onSelect(category.is_active, category)">
          <div class="categories__card--category" [ngClass]="{'categories__card--active': category?.is_active }">
            <div class="categories__card--image">
              <img [src]="category?.icon" [alt]="category?.name">
            </div>
            <div class="categories__card--over-category" *ngIf="category?.is_active"></div>
            <div class="categories__card--category-title">{{ category?.name }}</div>
          </div>
          <div class="categories__card--date-active flex flex__row flex__nowrap flex__j-center flex__align-center" *ngIf="category?.is_active">{{ category?.date }}</div>
          <div class="categories__card--date txt-gray-soft-v flex flex__row flex__nowrap flex__j-center flex__align-center" *ngIf="!category?.is_active">{{ category?.date }}</div>
        </div>
      </ng-container>
    </div>
  </div>
</div>