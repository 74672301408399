import { environment as env } from 'src/environments/environment';

export const getStaticSplitUrl = (file: string): string => {
  const country = localStorage.getItem('country').toLowerCase();
  return `${env.baseStaticsUri}/growth/prime-week/web/static/${country}/${file}`;
};

export const getBaseUrl = (apiPath, forcedCountry = '') => {
  if (!forcedCountry) {
    let country = localStorage.getItem('country') || env.default_country;
    country = country.toLowerCase();

    if (country === 'dev') {
      country = 'co';
    }

    return `${env[country].api}${apiPath}`;
  } else {
    return `${env[forcedCountry].api}${apiPath}`;
  }
};

export const internalBaseUri = (apiPath) => {
  const country = localStorage.getItem('country') || env.default_country;

  if (country === 'dev') {
    return `${env.gateway.internalBaseUri}${apiPath}`;
  } else {
    return `${env[country].api}${apiPath}`;
  }
};

export const COUNTRIES_WITHOUT_DECIMALS = ['co', 'cl', 'cr'];

export const formatCountryValue = (value, country) => {
  if (value.includes(',')) {
    const [value1, value2] = value.split(',');
    const decimals = value2.length === 1 ? `${value2.substring(0, 2)}0` : value2.substring(0, 2);

    return `${value1}${COUNTRIES_WITHOUT_DECIMALS.includes(country) ? '' : ','}${COUNTRIES_WITHOUT_DECIMALS.includes(country) ? '' : decimals}`;
  } else {
    return COUNTRIES_WITHOUT_DECIMALS.includes(country) ? value : `${value},00`;
  }
};

export const COUNTRIES  = {
  mx: 'America/Mexico_City',
  co: 'America/Bogota',
  br: 'America/Sao_Paulo',
  ar: 'America/Buenos_Aires',
  cl: 'America/Santiago',
  uy: 'America/Montevideo',
  pe: 'America/Lima',
  ec: 'America/Quito',
  cr: 'America/Costa_Rica',
};
