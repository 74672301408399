import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { AppWindow } from 'src/app/components/webview/components/header/header.component';
declare const window: AppWindow;

@Component({
  selector: 'app-exception-screens',
  templateUrl: './exception-screens.component.html',
  styleUrls: ['./exception-screens.component.sass']
})
export class ExceptionScreensComponent implements OnInit {
  @ViewChild('headerTitle', { static: false }) headerTitle: ElementRef;
  @ViewChild('headerSubtitle', { static: false }) headerSubtitle: ElementRef;

  @Input() requestError: boolean;
  @Input() bestOffers: any;

  private country = 'co';

  public showCloseButton = true;
  public showDivideLine = true;
  public titles = {
    no_offers_title_es: 'En el momento no hay ofertas disponibles',
    no_offers_subtitle_es: 'Estamos preparando ofertas para ti',
    error_title1_es: 'Oops...',
    error_title2_es: 'Algo salió mal',
    error_subtitle_es: 'Intenta ingresar a la sección nuevamente.',
    no_offers_title_pt: 'No momento, não temos ofertas disponíveis',
    no_offers_subtitle_pt: 'Estamos preparando ofertas para você',
    error_title1_pt: 'Oops...',
    error_title2_pt: 'Algo deu errado',
    error_subtitle_pt: 'Tente entrar novamente'
  };

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
    this.country = localStorage.getItem('country');
  }

  public onClose(): void {
    if (window.app !== undefined) {
      window.app.endCall();
    }
    if (window.appInterface !== undefined) {
      window.appInterface.endCall();
    }
    if (window.webkit !== undefined) {
      window.webkit.messageHandlers.endCall.postMessage('');
    }
  }

  public onRetry(): void {
    this.router.navigate(['/'], {
      queryParams: { country: localStorage.getItem('country'), userToken: localStorage.getItem('userToken') },
    }).catch();
  }

  public setExceptionImage(): string {
    if (this.requestError) {
      return '/assets/img/exception/error.png';
    }
    if (!this.bestOffers.length) {
      return '/assets/img/exception/not-offers.png';
    }
  }

  public setTitle(): string {
    const lang = this.country !== 'br' ? 'es' : 'pt';
    if (this.requestError) {
      return this.titles[`error_title1_${lang}`];
    }
    if (!this.bestOffers.length) {
      return this.titles[`no_offers_title_${lang}`];
    }
  }

  public setSubtitle(): string {
    const lang = this.country !== 'br' ? 'es' : 'pt';
    if (this.requestError) {
      return this.titles[`error_subtitle_${lang}`];
    }
    if (!this.bestOffers.length) {
      return this.titles[`no_offers_subtitle_${lang}`];
    }
  }

  public setTitle2(): string {
    const lang = this.country !== 'br' ? 'es' : 'pt';
    if (this.requestError) {
      return this.titles[`error_title2_${lang}`];
    }
  }

  public setFontSize(isTitle = true): string {
    if (window.innerWidth >= 504) {
      return isTitle ? 'font__size--32 font__line-height--34' : 'font__size--16';
    } else if (window.innerWidth >= 411) {
      return isTitle ? 'font__size--24 font__line-height--34' : 'font__size--14';
    } else if (window.innerWidth >= 375) {
      return isTitle ? 'font__size--24 font__line-height--24' : 'font__size--14';
    } else {
      return isTitle ? 'font__size--18 font__line-height--22' : 'font__size--10';
    }
  }
}
