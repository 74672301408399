import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {

  private promotions: BehaviorSubject<any>;

  constructor() {
    this.promotions = new BehaviorSubject<any>(null);
  }

  getUserValues(): Observable<any> {
    return this.promotions.asObservable();
  }

  setUserValues(newValue): void {
    this.promotions.next(newValue);
  }
}
