<div class="modal flex flex__nowrap flex__row flex__j-center flex__align-center" *ngIf="showModal">
  <div class="modal__container bg-black-a">
    <div class="modal__close" (click)="onCloseModal()"></div>
    <div class="modal__header flex flex__nowrap flex__row flex__j-space-between">
      <div #headerImage class="modal__header--image">
        <img [src]="tooltip?.image" [alt]="tooltip?.subtitle">
      </div>
      <div class="modal__header--details">
        <div class="txt-gray-soft ellipsis">{{ tooltip?.title }}</div>
        <div class="white-title">{{ tooltip?.subtitle }}</div>
      </div>
    </div>
    <div class="separator"></div>
    <div class="modal__content flex flex__nowrap flex__row flex__j-flex-start" (click)="onProduct($event)">
      <div class="modal__content--image">
        <img [src]="sponsoredProduct?.image" [alt]="sponsoredProduct?.title">
      </div>
      <div class="modal__content--details flex flex__column">
        <div class="ellipsis white-title mb__5">{{ sponsoredProduct?.title }}</div>
        <div class="ellipsis gray-title txt-gray-soft-v mb__5">{{ sponsoredProduct?.subtitle }}</div>
        <div class="mb__5 flex flex__nowrap flex__row flex__align-center">
          <div class="white-title-s font__size--10 mr__4">{{ price }}</div>
          <div class="dot mr__2"></div>
          <div class="font__nunino--regular font__size--10 font__line-height--18 txt__decoration-line-through txt-gray-soft">{{ discount }}</div>
        </div>
        <div class="box box__percentage flex flex__nowrap flex__row flex__align-center">
          <span class="mr__2">-</span> {{ sponsoredProduct?.discount}}
        </div>
      </div>
    </div>
  </div>
</div>