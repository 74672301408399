import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Components
import { SubscribePrimeComponent } from './subscribe-prime/subscribe-prime.component';
import { ExceptionScreensComponent } from './exception-screens/exception-screens.component';

@NgModule({
  declarations: [
    SubscribePrimeComponent,
    ExceptionScreensComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    SubscribePrimeComponent,
    ExceptionScreensComponent
  ]
})
export class SharedModule { }
