import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import * as globals from 'src/app/utils/globals';
import { DateTime } from 'luxon';

// import Swiper core and required components
import SwiperCore, {
  Pagination,
  Autoplay,
  A11y
} from 'swiper/core';

// install Swiper components
SwiperCore.use([Pagination, Autoplay, A11y]);

@Component({
  selector: 'app-feature-product',
  templateUrl: './feature-product.component.html',
  styleUrls: ['./feature-product.component.sass']
})
export class FeatureProductComponent implements OnInit, OnChanges {
  @Input() treatmentValue: any;
  @Input() isPrime: boolean;

  private zone = '';
  private subscribeModalUrl = '';

  public showOwnerTitle = false;
  public showSmallImage = false;
  public sectionTitle = '';
  public price = '';
  public discount = '';
  public slideShowItems = [];
  public swiperConfig: any;

  constructor() { }

  ngOnInit(): void {
    this.showOwnerTitle = window.innerWidth >= 504;
    this.showSmallImage = window.innerWidth >= 375;
  }

  // tslint:disable-next-line:typedef
  ngOnChanges(changes: SimpleChanges) {
    for (const propertyName in changes) {
      if (changes[propertyName]) {
        const change: any = changes[propertyName].currentValue;

        switch (propertyName) {
          default:
            if (change && change.sections && change.sponsored_products) {
              this.sectionTitle = change.sections.filter(x => x.type === 'featured_product')[0].title;
              this.zone = globals.COUNTRIES[localStorage.getItem('country')];
              const currentDayHour = DateTime.now().setZone(this.zone);
              const primeDay = `prime_day_${currentDayHour.weekday}`;
              const primeSection = change.sections.filter(x => x.type === 'prime_section')[0];
              this.subscribeModalUrl = primeSection.button.deeplink;
              this.slideShowItems = change.sponsored_products.filter(p => p.sponsored_product_key === primeDay);

              if (this.slideShowItems.length) {
                this.slideShowItems = this.slideShowItems[0].products;
                this.slideShowItems.map((item: any) => {
                  if (item.price.includes('**')) {
                    const [ price, discount ] = item.price.split(' **');
                    item.new_price = price;
                    item.new_discount = discount.split('**')[0];
                  } else {
                    item.new_discount = item.price;
                  }

                  return item;
                });
              }

              this.swiperConfig = {
                initialSlide: 0,
                slidesPerColumn: 1,
                slidesPerView: 1,
                loopedSlides: this.slideShowItems.length,
                spaceBetween: 0,
                pagination: { clickable: true },
                autoplay: { delay: 4500, disableOnInteraction: false },
                loop: true,
              };
            }
            break;
        }
      }
    }
  }

  public onProduct(product): void {
    window.location.href = this.isPrime ? product.deeplink : this.subscribeModalUrl;
  }
}
