import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { SplitFactory } from '@splitsoftware/splitio';
import { Observable } from 'rxjs';
import { TreatmentValuesService } from './treatment-values.service';

@Injectable({
  providedIn: 'root'
})
export class SplitConfigurationService {
  private splitClient: SplitIO.IClient;
  private treatments: SplitIO.Treatments;
  private isReady = false;
  private splitNames = ['prime_week_config'];

  constructor(private treatmentService: TreatmentValuesService) { }

  public getSplitClient(userId: string): Observable<any> {
    const factory: SplitIO.ISDK = SplitFactory({
      core: {
        authorizationKey: environment.split.js_authorizationKey,
        key: userId
      },
      startup: {
        readyTimeout: 1.5
      }
    });

    this.splitClient = factory.client();
    return Observable.create(async observer => {
      this.splitClient.on(this.splitClient.Event.SDK_READY, () => {
        this.isReady = true;
        observer.next({ client: this.splitClient, client_on: this.isReady });
        observer.complete();
      });
    });
  }

  public async getTreatments(country = 'CO'): Promise<void> {
    const attributes: SplitIO.Attributes = {
      country,
    };

    try {
      const treatmentResults: SplitIO.TreatmentsWithConfig = await this.splitClient.getTreatmentsWithConfig(
        this.splitNames,
        attributes,
      );

      this.treatmentService.setTreatmentValues(JSON.parse(treatmentResults.prime_week_config.config));
      this.destroyClient(this.splitClient);
    } catch (error) {
      this.destroyClient(this.splitClient);
    }
  }

  private destroyClient(client: SplitIO.IClient): void {
    if (client) {
      client.destroy();
      client = null;
    }
  }
}
