import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { getBaseUrl, internalBaseUri } from 'src/app/utils/globals';

@Injectable({ providedIn: 'root' })
export class PrimeService {
  private discountAvailability = '/api/ms/discounts-availability';
  private userAdress = '/api/ms/users-address';

  constructor(
    private http: HttpClient
  ) {}

  public getCategoriesCurrentWeek(): Observable<any> {
    const url = `${getBaseUrl(this.discountAvailability)}/categories/current-week`;

    return this.http.get(url);
  }

  public getUserAddresses(): Observable<any> {
    const url = `${getBaseUrl(this.userAdress)}/addresses`;

    return this.http.get(url);
  }

  public getDayPromotions(params: any): Observable<any> {
    const url = `${getBaseUrl(this.discountAvailability)}/prime-week`;

    return this.http.get(url, { params });
  }
}
