<div class="promotions general-padding" *ngIf="promotions.length || filterSelected">
  <div class="flex flex__row flex__nowrap flex__align-center flex__j-space-between">
    <div class="font__nunino--extra-bold font__size--20 font__line-height--20 font__letter-spacing--83-n">
      {{ title }}
    </div>
    <div class="flex flex__row flex__nowrap flex__align-center flex__j-space-between" *ngIf="showRemainingHour">
      <img class="mr__4" src="/assets/img/general/filled_filled_time.svg" alt="time image">
      <div class="promotions__hour font__nunino--extra-bold">{{remainingHours}}</div>
    </div>
  </div>

  <div class="promotions__overflow mb__24">
    <div class="promotions__discount flex flex__row flex__align-center flex__j-space-between">
      <ng-container *ngFor="let discount of discounts; let i=index">
        <div class="mr__6 promotions__discount--items box box--br-40 box__discount"
        [ngClass]="{'box__discount--active': i === 0}" (click)="onDiscountClick($event.target, discount)">{{ discount.title }}</div>
      </ng-container>
    </div>
  </div>

  <div class="flex flex__row flex__wrap flex__j-space-between">
    <ng-container *ngFor="let promotion of promotions; let i=index">
      <div #promotionsCard class="promotions__card flex flex__column mr__0" (click)="onSelect(promotion)" [ngClass]="isOdd(promotionsCard, i) && 'odd'">
        <div class="box__offer box__offer--promo">
          <div class="box__offer--image">
            <img [src]="promotion?.image" [alt]="promotion?.product_name">
          </div>
          <div class="promotions__card--percentage box box__percentage flex flex__nowrap flex__row flex__align-center flex__j-center">
            <div class="mr__2">-{{ promotion?.discount }}%</div>
          </div>
          <div class="box__owner">
            <img [src]="promotion?.store_image" [alt]="promotion?.product_name">
          </div>
        </div>
        <div class="ellipsis font__nunino--regular font__size--12 font__line-height--14 font__letter-spacing--0 txt-gray-soft-v">{{ promotion?.product_name }}</div>
        <div class="flex flex__nowrap flex__row flex__j-flex-start">
          <div class="mr__2 font__nunino--extra-bold font__size--14 font__line-height--18 font__letter-spacing--0 txt-black-a">{{ promotion?.currency }}{{ promotion?.price_with_discount }}</div>
          <div class="box__prime flex flex__row flex-nowrap flex-j-center flex__align-center">
            <div class="box__prime--crow"></div>
          </div>
        </div>
        <div class="txt__decoration-line-through font__nunino--regular font__size--14 font__line-height--18 font__letter-spacing--0 txt-gray-soft">{{ promotion?.currency }}{{ promotion?.price }}</div>
      </div>
      <div class="subscribe-prime" *ngIf="showSubscribePrime(i)">
        <app-subscribe-prime [isPromotion]="isPromotion"></app-subscribe-prime>
      </div>
    </ng-container>
    <div class="loading" *ngIf="showLoading">
      <div class="ball"></div>
      <div class="ball"></div>
      <div class="ball"></div>
    </div>
  </div>
</div>