import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';

import { PrimeService } from 'src/app/services/prime.service';
import { UserService } from 'src/app/services/user.service';
import * as globals from 'src/app/utils/globals';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-promotions-day',
  templateUrl: './promotions-day.component.html',
  styleUrls: ['./promotions-day.component.sass']
})
export class PromotionsDayComponent implements OnInit, OnDestroy, OnChanges {
  @Output() getPrime: EventEmitter<any> = new EventEmitter<any>();
  @Output() getBestOffers: EventEmitter<any> = new EventEmitter<any>();
  @Output() requestError: EventEmitter<any> = new EventEmitter<any>();
  @Input() addresses: any;
  @Input() treatmentValue: any;

  private zone = '';
  private country = 'co';
  private userAdress: any;
  private params: any;
  private pagination = 1;
  private pageAvailable = 1;
  private discountFrom = 0;
  private discountTo = 100;
  private initiaGet = true;
  private subscribeModalUrl = '';

  public filterSelected = false;
  public showLoading = false;
  public remainingHours = '';
  public showRemainingHour = false;
  public hourInterval: any;
  public isPrime: false;
  public title = '';
  public isPromotion = true;
  public discounts = [
    { title: 'Todos', form: 0, to: 100, order: 1 },
    { title: 'Hasta 50% off', form: 40, to: 50, order: 2 },
    { title: 'Hasta 40% off', form: 30, to: 40, order: 3 },
    { title: 'Hasta 30% off', form: 20, to: 30, order: 4 },
    { title: 'Hasta 20% off', form: 0, to: 20, order: 5 },
  ];

  public promotions = [];

  constructor(
    private primeService: PrimeService
  ) { }

  ngOnInit(): void {
    this.showRemainingHour = window.innerWidth >= 375;
    this.country = localStorage.getItem('country');
    this.zone = globals.COUNTRIES[localStorage.getItem('country')];
    this.getRemainingHours();
  }

  // tslint:disable-next-line:typedef
  ngOnChanges(changes: SimpleChanges) {
    for (const propertyName in changes) {
      if (changes[propertyName]) {
        const change: any = changes[propertyName].currentValue;

        switch (propertyName) {
          case 'treatmentValue':
            if (change && change.sections) {
              const primeSection = change.sections.filter(x => x.type === 'prime_section')[0];
              this.subscribeModalUrl = primeSection.button.deeplink;
              this.title = change.sections.filter(x => x.type === 'all_offers')[0].title;
            }
            break;
          default:
            if (change){
              this.userAdress = change;
              this.getDayPromotions();
              this.setScrollEvent();
            }
            break;
        }
      }
    }
  }

  ngOnDestroy(): void {
    clearInterval(this.hourInterval);
  }

  private setScrollEvent(): void {
    window.addEventListener('scroll', () => {
      const { scrollTop, scrollHeight, clientHeight } = document.documentElement;
      const totalScroll = clientHeight + scrollTop + 5;

      if (totalScroll > scrollHeight && this.pagination < this.pageAvailable) {
        this.pagination++;
        this.getDayPromotions();
        this.showLoading = true;
      } else {
        this.showLoading = false;
      }
    });
  }

  private getDayPromotions(filter = false): void {
    this.params = {
      section: 'unmissable',
      lng: this.userAdress.lng,
      lat: this.userAdress.lat,
      size: 10,
      page: this.pagination,
      discount_from: this.discountFrom,
      discount_to: this.discountTo
    };

    this.primeService.getDayPromotions(this.params).subscribe((res: any) => {
      this.showLoading = false;
      if (!this.promotions.length) {
        this.promotions = res.page_items;
      } else {
        this.promotions = [
          ...(filter ? [] : this.promotions),
          ...res.page_items
        ];
      }

      this.pageAvailable = res.page_available;

      if (this.initiaGet && this.pagination === 1) {
        this.initiaGet = false;
        this.isPrime = res.is_prime;
        this.getPrime.emit(this.isPrime);
        this.getBestOffers.emit(this.promotions);
        this.discounts = res.filters || this.discounts;
        this.discounts = this.discounts.sort((a, b) => a.order - b.order);
        this.pagination++;
        this.promotions = [];

        if (this.pageAvailable > 1) {
          return this.getDayPromotions(filter);
        }
      }
    }, error => {
      this.showLoading = false;
      this.requestError.emit(true);
    });
  }

  private getRemainingHours(): void {
    const currentDayHour = DateTime.now().setZone(this.zone);
    const endDayHour = DateTime.now().setZone(this.zone).set({ hour: 24, minute: 0 });
    const diffHours = endDayHour.diff(currentDayHour, ['hours', 'minutes']).toObject();

    this.remainingHours = `${DateTime.now().set(diffHours).toFormat('HH:mm')}h`;
    this.hourInterval = setInterval(this.getRemainingHours, 1000);
  }

  public onDiscountClick(e: HTMLElement, discount: any): void {
    this.showLoading = true;
    const elements = document.querySelectorAll('.promotions__discount--items');
    elements.forEach((element: HTMLElement) => {
      if (element && element.classList.contains('box__discount--active')) {
        element.classList.remove('box__discount--active');
      }
    });

    e.classList.add('box__discount--active');
    this.discountFrom = discount.from;
    this.discountTo = discount.to;
    this.filterSelected = true;
    this.pagination = 1;
    this.getDayPromotions(true);
  }

  public showSubscribePrime(index): boolean {
    return !this.isPrime && ((index + 1) % 6 === 0);
  }

  public isOdd(card: HTMLElement, index: number): boolean {
    if (index % 2 !== 0) {
      const box = card.childNodes[0] as HTMLElement;
      const cardWidth = +card.offsetWidth;
      const boxWidth = +box.offsetWidth;

      if (cardWidth > boxWidth) {
        const widthDiff = cardWidth - boxWidth;
        card.style.paddingLeft = `${widthDiff}px`;
      }

      return true;
    }

    return false;
  }

  public onSelect(promotion): void {
    window.location.href = this.isPrime ? promotion.link.sref : this.subscribeModalUrl;
  }
}
