<div class="exception padding-button">
  <div class="exception__header">
    <div class="exception__header--button btn-row" (click)="onClose()" *ngIf="showCloseButton"></div>
    <div #headerTitle class="exception__header--title font__nunino--extra-bold font__letter-spacing--1p5-n txt-black-a" [ngClass]="setFontSize(true)">
      {{ setTitle() }}
    </div>
    <div class="exception__header--title font__nunino--extra-bold font__letter-spacing--1p5-n txt-black-a" [ngClass]="setFontSize(true)" *ngIf="requestError">
      {{ setTitle2() }}
    </div>
    <div #headerSubtitle class="exception__header--subtitle font__nunino--regular font__line-height--20 font__letter-spacing--0 txt-gray-soft-v" [ngClass]="setFontSize(false)">
      {{ setSubtitle() }}
    </div>
    <div class="exception__header--line" *ngIf="showDivideLine"></div>
  </div>
  <div class="exception__image">
    <img [src]="setExceptionImage()" alt="Error image">
  </div>
  <div class="exception__button" *ngIf="requestError">
    <button class="btn-subscribe" (click)="onRetry()">
      <span class="font__nunino--extra-bold font__line-height--20 font__letter-spacing--0">Intentar de nuevo</span>
    </button>
  </div>
</div>
